import {
    faMedium,
    faTwitter,
    faGithub,
    faGitlab,
    faLinkedin
} from '@fortawesome/free-brands-svg-icons';
import {
    faGlobe,
    faEnvelope,
    faCoffee,
    faFileAlt,
    faFile,
    faLink,
    faCaretDown,
} from '@fortawesome/free-solid-svg-icons';

export const ICONS = [
    faMedium,
    faTwitter,
    faGithub,
    faGlobe,
    faEnvelope,
    faCoffee,
    faFileAlt,
    faFile,
    faGitlab,
    faLinkedin,
    faLink,
    faCaretDown,
];